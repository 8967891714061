import { styled, theme } from '@/src/stitches.config'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import Image from 'next/image'

const BannerWrapperBackground = styled('div', {
    background: `-webkit-linear-gradient(top right, ${theme.colors.secondary} 20%, ${theme.colors.primary} 74%)`,
    py: 30,
})

const BannerWrapper = styled('div', {
    display: 'flex',
    fd: 'column',
    width: '$default',
    maxWidth: '$defaultMax',
    ai: 'center',
    jc: 'center',
    margin: 'auto',
    gap: 20,
    '@media (min-width:750px)': {
        fd: 'row',
    },
})

const ImageAndTextWrapper = styled('div', {
    display: 'flex',
    fd: 'column-reverse',
    ai: 'center',
    gap: 20,
    '& img': {
        width: '90%',
        maxWidth: 250,
        height: 'auto',
    },
    '@media (min-width:750px)': {
        flex: 1,
        fd: 'row',
        width: '50%',
        gap: 30,
        '& img': {
            width: '40%',
        },
    },
})

const ButtonWrapper = styled('div', {
    width: 'min-content',
    display: 'flex',
    jc: 'center',
    fd: 'column',
    gap: 10,
    '@media (min-width:750px)': {
        fd: 'row',
        flexWrap: 'wrap',
    },
    '@media (min-width: 1200px)': {
        width: 'max-content',
    },
})

const StyledText = styled('p', {
    my: 0,
    color: 'white',
    fontSize: '1.7rem',
    fontWeight: 'bold',
    textAlign: 'center',
    '@media (min-width:750px)': {
        textAlign: 'left',
        minWidth: 200,
    },
})

export const StillSearchingForPerfectGift = () => {
    return (
        <BannerWrapperBackground>
            <BannerWrapper>
                <ImageAndTextWrapper>
                    <Image
                        src={'/images/greeting-card-pieces.png'}
                        alt={'Greeting card image'}
                        width={250}
                        height={145}
                        priority={false}
                    />
                    <StyledText>
                        Still searching for the PerfectGift?
                    </StyledText>
                </ImageAndTextWrapper>
                <ButtonWrapper>
                    {/*// TODO change back to /virtual-visa-gift-cards once pages are implemented */}
                    <LinkButton
                        url={'https://www.perfectgift.com/virtual-visa-gift-cards '}
                        variant={'whiteOutlineTransparent'}
                        label={'Buy Virtual Visa'}
                        showArrow={true}
                        textTransform={'uppercase'}
                        buttonWidth={'275px'}
                    />
                    {/*// TODO change back to /predesigned-visa-gift-card once pages are implemented */}
                    <LinkButton
                        url={'https://www.perfectgift.com/predesigned-visa-gift-card'}
                        variant={'whiteOutlineTransparent'}
                        label={'Buy Plastic Visa'}
                        showArrow={true}
                        textTransform={'uppercase'}
                        buttonWidth={'275px'}
                    />
                </ButtonWrapper>
            </BannerWrapper>
        </BannerWrapperBackground>
    )
}
