import { GradientText } from '@/src/common/components/marketing/heros/StyledComponents'

// accepts a full string and specific substring to apply gradient to
function addGradientToSubstring(
    fullText: string,
    substring: string
): JSX.Element {
    // Escape any special characters in the substring to avoid issues with RegExp
    const escapedSubstring = substring.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')

    // Create a regular expression to find all occurrences of the substring
    const regex = new RegExp(`(${escapedSubstring})`, 'gi')

    // Split the string by the matched substring, keeping the separator
    const parts = fullText.split(regex)

    return (
        <>
            {parts.map((part, index) =>
                part.toLowerCase() === substring.toLowerCase() ? (
                    <GradientText key={index}>{part}</GradientText>
                ) : (
                    part
                )
            )}
        </>
    )
}

export default addGradientToSubstring
