import {
    Configure,
    HitsPerPage,
    InstantSearch,
    InstantSearchServerState,
    InstantSearchSSRProvider,
    useHits,
} from 'react-instantsearch'
import { styled } from '@/src/stitches.config'
import { SearchBoxInput } from '@/src/modules/algolia/SearchBoxInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { HitsList } from '@/src/modules/algolia/HitsList'
import { PaginationControls } from '@/src/modules/algolia/PaginationControls'
import { MenuListAlphabetical } from '@/src/modules/algolia/MenuListAlphabetical'
import { InsightsMiddleware } from '@/src/modules/algolia/InsightsMiddleware'
import algoliasearch from 'algoliasearch/lite'
import { useBrandSearchContext } from '@/src/modules/algolia/BrandSearchContext'
import BrandSearchRefinements from '@/src/modules/algolia/BrandSearchRefinements'
import { useEffect } from 'react'

const searchClient = algoliasearch(
    String(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID),
    String(process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_SEARCH)
)
const indexName = String(process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME_BRANDS)

const SearchAndFilter = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '1vw',
    textAlign: 'left',
    '@sm': {
        ml: 20,
    },
})
const SearchWrapper = styled('div', {
    flex: 1,
    position: 'relative',
    '@media (max-width: 390px)': {
        '& svg': {
            display: 'none',
        },
    },
})
const StyledIcon = styled(FontAwesomeIcon, {
    position: 'absolute',
    right: '12px',
    top: '12px',
    color: '$primary',
    cursor: 'pointer',
    flex: 0,
})

const StyledMenuListAlphabetical = styled(MenuListAlphabetical, {
    background: '$lightest',
    '.ais-Menu-list': {
        marginBottom: 10,
    },
    '.ais-Menu-item': {
        background: 'none',
    },
})
const StyledHitsPerPage = styled(HitsPerPage, {
    width: 'min-content',
    ml: 'auto',
    pb: 10,
    display: 'none',
})

export interface IBrandSearch {
    serverState?: InstantSearchServerState
    children?: JSX.Element | JSX.Element[]
    validBrands?: string[]
}

// is used on brand search page only
export const PGPlusBrandSearch = ({
    serverState,
    children,
    validBrands,
}: IBrandSearch) => {
    const limitBrandsQuery: string =
        !!validBrands && validBrands?.length > 0
            ? ` AND slug:${validBrands.join(' OR slug:')}`
            : ''

    return (
        <InstantSearchSSRProvider {...serverState}>
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                <Configure
                    filters={'pgc_enabled:true AND NOT categories:"Open Loop" AND giftya_egift_enabled:true AND NOT slug:"pg-plus"' + limitBrandsQuery}
                    clickAnalytics
                />
                {children}
                <InsightsMiddleware />
            </InstantSearch>
        </InstantSearchSSRProvider>
    )
}

export const BrandSearchAndFilter = () => {
    const { setBrandHits, showMagnifyingGlass } = useBrandSearchContext()
    const { hits } = useHits()

    // updates the brand hits for analytic events
    useEffect(() => {
        setBrandHits(hits)
    }, [hits, setBrandHits])

    return (
        <SearchAndFilter className={'brand-search'}>
            <SearchWrapper>
                {showMagnifyingGlass && (
                    <StyledIcon
                        icon={faMagnifyingGlass}
                        size={'2x'}
                        data-testid={'search-icon'}
                    />
                )}
                <SearchBoxInput />
            </SearchWrapper>
        </SearchAndFilter>
    )
}

export const BrandHits = () => {
    return (
        <>
            <StyledMenuListAlphabetical
                id={'alphabetical-filter'}
                data-testid={'alphabetical-filter'}
            />
            <BrandSearchRefinements />
            <StyledHitsPerPage
                items={[
                    {
                        label: '36 hits per page',
                        value: 36,
                        default: true,
                    },
                ]}
            />
            <HitsList issearch={'true'} />
            <PaginationControls />
        </>
    )
}
